/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h3: "h3",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "horizon-ix-hearing-aids-by-hearcom-help-people-overcome-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-ix-hearing-aids-by-hearcom-help-people-overcome-hearing-loss",
    "aria-label": "horizon ix hearing aids by hearcom help people overcome hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon IX Hearing Aids by Hear.com Help People Overcome Hearing Loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/LND089_horizon_ABE1817_mobile-copy-2.jpg",
    alt: "Horizon IX Hearing Aids by Hear.com",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing every word clearly and responding with ease are aspects of good conversations that are often taken for granted — until you can no longer hear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Because there are so many hearing aid companies and choices in the market today, it’s hard to know who to trust. You’re probably also wondering which device is best for you. But like many other people with untreated hearing loss, there are probably several other things holding you back."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "the-problem-hearing-aids-have-a-reputation-of-being-big-bulky-expensive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-problem-hearing-aids-have-a-reputation-of-being-big-bulky-expensive",
    "aria-label": "the problem hearing aids have a reputation of being big bulky expensive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Problem: Hearing aids have a reputation of being big, bulky, expensive"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "More than 48 million people in the U.S. have hearing loss, but many still continue to put off getting hearing aids. Why? Modern hearing aids unfortunately suffer from the reputation of older hearing aids from 20+ years ago. Most people still think:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "They’re big and bulky."), " Hearing aids through the late 20th century were very visible to others. They also didn’t take into account the wearer’s personal preferences or hearing needs."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "They’re expensive, but don’t work."), " The high price of hearing aids years ago didn’t match the poor level of technology, which didn’t do much to improve speech clarity or background noise."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "They’ll age you."), " People still associate big, bulky hearing aids with feeling and looking old."), "\n"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "the-solution-modern-nearly-invisible-hearing-aids-customized-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-solution-modern-nearly-invisible-hearing-aids-customized-for-you",
    "aria-label": "the solution modern nearly invisible hearing aids customized for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Solution: Modern, nearly invisible hearing aids, customized for you"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com has made affordable, modern hearing aids possible in the United States. Not only have we completely transformed how people access, test and buy hearing aids, but we also just launched our own device that’s attracting people of all ages."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But before we can determine the best product for you, we learn more about your hearing loss and lifestyle. We can then recommend and customize one of the best hearing aids in the market, like our new ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/go-ix/",
    className: "c-md-a"
  }, "Horizon Go IX hearing aid"), ", which has been applauded by top audiologists for both its design and capabilities:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A nearly invisible design that no one will notice"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A boosted Speech Focus that makes speech unbelievably clear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth connectivity"), " so you can stream what you love right into your ears"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "And even rechargeability"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Built for adults of all ages, these definitely are not the hearing aids of the past."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hear.com ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "Horizon IX hearing aids"), " are now available for a 45-day no-risk trial. We also offer financing plans for any budget and will work to maximize insurance benefits. There’s never been a better time for those with hearing loss."), "\n", React.createElement(LandingPageCta, {
    copy: "Start your 45-day no-risk trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
